<template>
  <select v-model="value" class="form-select" id="sms-activity">
    <option value="">Select option</option>
    <option v-bind:key="item.id" v-bind:value="item.id" v-for="item in items">{{
      item.name
    }}</option>
  </select>
</template>

<script>
export default {
  props: {
    items: Array,
    selectedSmsActivity: String,
  },

  computed: {
    value: {
      get: function getValue() {
        return this.selectedSmsActivity;
      },
      set: function setValue(newVal) {
        this.$emit("update:value", newVal);
      },
    },
  },

  created() {
    this.$emit("update:value", this.selectedSmsActivity);
  },
};
</script>

<style></style>
