<template>
  <div id="app">
    <div v-if="currentStep.key === 'step1'" id="step1" class="step">
      <select-activity
        @update:value="onChangeSelectedActivity"
        v-bind:items="smsActivities"
        :selectedSmsActivity="selectedActivity"
      ></select-activity>
    </div>
    <div v-if="currentStep.key === 'step2'" id="step2" class="step">
      <select-engagement-type
        :engagementTypes="engagementTypes"
        :selectedEngagementType="selectedEngagementType"
        @update:value="onChangeEngagementType"
      ></select-engagement-type>
    </div>
  </div>
</template>

<script>
import SelectActivity from "./components/SelectActivity.vue";
import SelectEngagementType from "./components/SelectEngagementType.vue";
import PostMonger from "postmonger";

export default {
  name: "App",
  components: { SelectActivity, SelectEngagementType },
  data() {
    const steps = [
      { label: "Choose the SMS activity", key: "step1" },
      { label: "Choose the engagement type", key: "step2" },
    ];

    const engTypes = [
      {
        id: "click",
        name: "Click in SMS",
        eventName: "Click",
      },
      {
        id: "delivery",
        name: "SMS Delivery",
        eventName: "Delivery",
      },
    ];

    return {
      smsActivities: [],
      engagementTypes: engTypes,
      interaction: null,
      activity: null,
      selectedActivity: "",
      selectedEngagementType: engTypes[0].id,
      steps,
      currentStep: steps[0],
      connection: new PostMonger.Session(),
    };
  },

  methods: {
    /**
     * Получение journey
     */
    onRequestInteraction(requestedInteraction) {
      this.interaction = requestedInteraction;

      //фильтруем активити, выбираем СМС
      var smsActivities = requestedInteraction.activities.filter((x) => {
        if (x.metaData.isConfigured) {
          // return x.type === "EMAILV2";
          return x.type === "SMSSYNC";
        }

        return false;
      });
      //показываем автивити
      this.smsActivities = smsActivities.map((item) => {
        var name = item.name;

        if (name == null || name.length === 0) {
          name = "SMS";
        }

        return {
          name,
          id: item.key,
        };
      });
    },

    /**
     * Инициализация текущей активити
     */
    initActivity(payload) {
      this.activity = payload;

      var hasInArguments =
        payload.arguments &&
        payload.arguments.execute &&
        payload.arguments.execute.inArguments &&
        payload.arguments.execute.inArguments.length > 0;

      var inArguments = hasInArguments
        ? payload.arguments.execute.inArguments
        : {};

      inArguments.forEach((inArgument) => {
        if (inArgument.smsActivity) {
          this.selectedActivity = inArgument.smsActivity;
        }

        if (inArgument.engagementType) {
          this.selectedEngagementType = inArgument.engagementType;
        }
      });

      //проверяем, есть ли выбранная активити или нет, если нет, то очищаем
      if (!this.smsActivities.find((x) => x.id === this.selectedActivity)) {
        this.selectedActivity = "";
        this.setEnableNextButton(false);
        this.updateActivity();
        this.activity.metaData.isConfigured = false;
      }
    },
    /**
     * Срабатываем при изменении выбранной активити пользователем
     */
    onChangeSelectedActivity(selectedSmsActivity = "") {
      this.selectedActivity = selectedSmsActivity;
    },
    /**
     * Срабатываем при измененении выбранного типа взаимодействия
     */
    onChangeEngagementType(selectedEngagementType = "") {
      this.selectedEngagementType = selectedEngagementType;
    },

    updateActivity() {
      const payload = this.activity;

      const inArguments = [
        {
          smsActivity: this.selectedActivity, //выбранная СМС активити
          engagementType: this.selectedEngagementType, //выбранный тип взаимодейтствия
          journeyId: this.interaction.id, //ид journey, может быть пустым, если journey еще не создан
          journeyKey: this.interaction.key,
          contactId: "{{Contact.Id}}",
          contactKey: "{{Contact.Key}}",
        },
      ];

      if (payload.arguments && payload.arguments.execute) {
        payload.arguments.execute.inArguments = inArguments;
      }
    },

    /**
     * Метод для сохранения настроек текущей активити
     */
    save() {
      const payload = this.activity;

      if (payload && payload.arguments) {
        var selectedSmsActitityText = this.smsActivities.find(
          (x) => x.id === this.selectedActivity
        ).name;

        const engType = this.engagementTypes.find(
          (x) => x.id === this.selectedEngagementType
        );
        var name = `${engType.eventName} ${selectedSmsActitityText}`;

        payload.name = name;

        this.updateActivity();

        //указываем, что активити настроена
        payload.metaData.isConfigured = true;
        payload.metaData.type = "AbbotSMSDecistion";

        this.connection.trigger("updateActivity", payload);
      }
    },

    onClickedNext() {
      if (this.selectedActivity.length > 0) {
        if (this.currentStep.key === "step2") {
          this.save();
        } else {
          this.connection.trigger("nextStep");
        }
      }
    },

    onClickedBack() {
      this.connection.trigger("prevStep");
    },

    onGotoStep(step) {
      this.currentStep = this.steps.find((x) => x.key === step.key);
      this.connection.trigger("ready");
    },

    setEnableNextButton(enable) {
      this.connection.trigger("updateButton", {
        button: "next",
        enabled: enable,
      });
    },
  },

  watch: {
    selectedActivity(selectedSmsActivity = "") {
      this.setEnableNextButton(selectedSmsActivity.length > 0);
    },
    selectedEngagementType(selectedEngagementType = "") {
      this.connection.trigger("updateButton", {
        button: "next",
        enabled: selectedEngagementType.length > 0,
      });
    },
  },

  /**
   * Подписывыаемся на события journey
   */
  created() {
    this.connection.on("initActivity", this.initActivity);
    this.connection.on("requestedInteraction", this.onRequestInteraction);

    this.connection.on("clickedNext", this.onClickedNext);
    this.connection.on("clickedBack", this.onClickedBack);
    this.connection.on("gotoStep", this.onGotoStep);

    this.connection.trigger("requestInteraction");
    this.connection.trigger("ready");
  },
};
</script>

<style lang="scss">
body {
  padding: 20px;
  margin: 0;
}
</style>
